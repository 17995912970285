import axios from 'axios';

export async function fetchUsersRanking() {
  const apiUrl = process.env.REACT_APP_RANKING_URL as string;
  const apiToken = process.env.REACT_APP_RANKING_TOKEN as string;

  return axios.get(apiUrl, {
    headers: {
      Authorization: apiToken,
    },
  })
    .then(response => {

      // const { ranking, failedRequest } = response.data.data;
      // console.log(ranking);

      // if (failedRequest.length > 0) {
      //   console.error("Failed requests:", failedRequest);
      // }
      if (!response.data) {
        return
      }
      return { status: response.data.status, data: response.data.data, error: null };
    })
    .catch(error => {
      console.error("Error fetching rankings:", error);
      return (error.response && error.response.data) ? error.response.data : null;
    });
}