import { ChangeEvent, useState, MouseEvent, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Card, TextField,
    SelectChangeEvent, Box, FormControl, InputLabel,
    Select, MenuItem, TablePagination
} from '@mui/material';

import ludusTheme from '../ludusTheme.d';
import { fetchUsersRanking } from '../services/fetchUsersRanking';


const Ranking = () => {

    const [rankingData, setRankingData] = useState<{ id: number, userName: string, scenary: string, company: string, points: number }[]>([])

    const [filter, setFilter] = useState('')
    const [filterCompany, setFilterCompany] = useState('Todos');
    const [filterScenary, setFilterScenary] = useState('Todos');
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    useEffect(() => {
        const requestRankingData = async () => {
            const { status, data, error } = await fetchUsersRanking()
            if (status !== 200) {
                return console.error(error)
            }
            setRankingData(data)
        }
        requestRankingData()
    }, [])


    let empresasUnicas: string[] = ['Todos']
    let escenariosUnicos: string[] = ['Todos']



    rankingData.forEach(row => {
        if (!empresasUnicas.includes(row.company)) {
            empresasUnicas.push(row.company)
        }
        if (!escenariosUnicos.includes(row.scenary)) {
            escenariosUnicos.push(row.scenary)
        }
    })

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
        setPage(0);
    }

    const handleCompanySelect = (event: SelectChangeEvent) => {
        setFilterCompany(event.target.value);
        setPage(0);
    };

    const handleScenarySelect = (event: SelectChangeEvent) => {
        setFilterScenary(event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const filterData = rankingData.filter(row => {

        const matchesFilter = !filter ||

            row.userName.toLowerCase().includes(filter.toLowerCase()) ||

            row.company.toLowerCase().includes(filter.toLowerCase()) ||

            row.scenary.toLowerCase().includes(filter.toLowerCase());


        const matchesCompany = filterCompany === 'Todos' || row.company === filterCompany;

        const matchesScenary = filterScenary === 'Todos' || row.scenary === filterScenary;


        return matchesFilter && matchesCompany && matchesScenary;

    })
    .sort((a, b) => b.points - a.points);

    const paginatedFilterData = filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
        <div>
            <TableContainer component={Card}
                sx={{
                    boxShadow: '0px 0px 15px 4px rgba(255,255,255,0.1)',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    borderRadius: 5,
                    marginTop: 1
                }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: 1,
                    paddingBottom: 2,
                }}>
                    <TextField
                        label="Buscar"
                        variant="outlined"
                        value={filter}
                        onChange={handleFilterChange}
                        size="small"
                        sx={{
                            width: '33%',
                            margin: 1,
                            verticalAlign: 'top',
                            top: 20,
                            '& .MuiInputBase-input': {
                                color: 'white',
                                fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'lightgray',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: ludusTheme.palette.primary.main,
                                fontWeight: "500",
                                fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
                            },
                        }}
                    />
                    <FormControl
                        sx={{
                            width: '33%',
                            margin: 1,
                            top: 20,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'lightgray',
                                },
                                '& .Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                            '& .MuiSvgIcon-root ': {
                                color: 'white',
                                display: {
                                    xs: 'none', sm: 'none', md: 'block', lg: 'block'
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: ludusTheme.palette.primary.main,
                                fontWeight: "500",
                                fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
                            },
                        }}>
                        <InputLabel variant='outlined' id="demo-simple-select-label" size="small">
                            Empresas
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Empresas"
                            value={filterCompany}
                            onChange={handleCompanySelect}
                            sx={{
                                color: 'white',
                                height: '40px',
                                verticalAlign: 'top',
                                fontSize: { xs: 12, sm: 12, md: 14, lg: 16 },
                                lineHeight: { xs: 1, sm: 1, md: 1.5, lg: 1.5 },
                            }}
                        >
                            {empresasUnicas.map((empresa, index) => (
                                <MenuItem key={index} value={empresa} sx={{ fontSize: { xs: 10, sm: 10, md: 14, lg: 14 }, padding: 1, margin: 0.5 }}>
                                    {empresa}
                                </MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{
                            width: '33%',
                            margin: 1,
                            top: 20,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'lightgray',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white',
                                },
                            },
                            '& .MuiSvgIcon-root ': {
                                color: 'white',
                                display: {
                                    xs: 'none', sm: 'none', md: 'block', lg: 'block'
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: ludusTheme.palette.primary.main,
                                fontWeight: "500",
                                fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
                            },
                        }}>
                        <InputLabel variant='outlined' id="demo-simple-select-label" size='small'>
                            Escenarios
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Empresas"
                            value={filterScenary}
                            onChange={handleScenarySelect}
                            sx={{
                                color: 'white',
                                height: '40px',
                                verticalAlign: 'top',
                                fontSize: { xs: 12, sm: 12, md: 14, lg: 16 },
                                lineHeight: { xs: 1, sm: 1, md: 1.5, lg: 1.5 },
                            }}
                        >
                            {escenariosUnicos.map((escenario, index) => (
                                <MenuItem key={index} value={escenario} sx={{ fontSize: { xs: 10, sm: 10, md: 14, lg: 14 }, padding: 1, marginX: 0.5 }}>
                                    {escenario}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            '& th':
                            {
                                fontFamily: 'Barlow Condensed',
                                fontWeight: '500',
                                color: ludusTheme.palette.primary.main,
                                fontSize: { xs: 12, sm: 16, md: 24, lg: 24 }
                            },
                        }}>
                            <TableCell align="center">Usuario</TableCell>
                            <TableCell align="center">Empresa</TableCell>
                            <TableCell align="center">Escenario</TableCell>
                            <TableCell align="center">Puntos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedFilterData.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '&:hover': { backgroundColor: 'rgba(53, 227, 152, 0.1)' },
                                    '& td': {
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        color: 'white',
                                        fontSize: { xs: 10, sm: 12, md: 18, lg: 18 }
                                    },
                                }}
                            >
                                <TableCell align='center'>{row.userName}</TableCell>
                                <TableCell align="center">{row.company}</TableCell>
                                <TableCell align="center">{row.scenary}</TableCell>
                                <TableCell align="center">{row.points}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filterData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                    color: "white",
                    '& .MuiTablePagination-selectLabel': {
                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 }
                    },
                    '& .MuiTablePagination-gutters': {
                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 }
                    },
                    '& .MuiTablePagination-toolbar': {
                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 }
                    },
                    '& .MuiTablePagination-displayedRows': {
                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 }
                    },
                    '& .MuiSvgIcon-root ': {
                        color: 'white',
                        fontSize: { xs: 10, sm: 12, md: 18, lg: 20 }
                    },
                }}
            />
        </div >
    )
}

export default Ranking
