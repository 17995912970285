import { CSSProperties } from 'react'
import { createTheme, ThemeOptions } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import backgroundImage from './images/vr-img.jpg'
import '@fontsource/roboto'
import '@fontsource/barlow-condensed'
import '@fontsource/inter'

//** Custom Variant */
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		demoVariant: true
	}
}

export interface ExtendedTypographyOptions extends TypographyOptions {
	demoVariant: CSSProperties
}


//** Custom Theme */
const ludusTheme = createTheme({
	breakpoints: {
		values: {
		  xs: 0,
		  sm: 370,
		  md: 600,
		  lg: 900,
		  xl: 1200,
		},
	  },
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					minHeight: '100vh',
					overflow: 'hidden',
					margin: 0,
					padding: 0,
					width: '100%',
				},
				button: {
					'&.MuiButton-root': {
						borderRadius: '25px',
					},
					'&.MuiButton-outlined': {
						border: '2px solid',
						'&:hover': {
							border: '2px solid',
							boxShadow: '0px 15px 20px',
						},
					},
				},
			},
		},
		MuiButtonRoot: {
			root: {
				style: {
					borderRadius: '25px',
					'&.MuiButton-outlined': {
						border: '2px solid',
						'&:hover': {
							border: '2px solid',
							boxShadow: '0px 15px 20px',
						},
					},
				},
			},
		},
		MuiFormLabelRoot: {
			MuiInputLabelRoot: {
				style: {
					color: '#757575',
				},
			},
		},
		MuiDrawer: {
			MuiDrawerPaper: {
				style: {
					width: 240,
				},
			},
		},
	},
	palette: {
		// mode:"dark",
		primary: {
			main: '#35e398',
			light: '#77ffc9',
			dark: '#00b069',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#35e398',
			light: '#77ffc9',
			dark: '#00b069',
		},
		error: {
			main: '#dc3545',
			light: '#ff6b70',
			dark: '#a3001e',
		},
		warning: {
			main: '#f8b133',
			light: '#ffe366',
			dark: '#c08200',
		},
		info: {
			main: '#346165',
			light: '#618f93',
			dark: '#02373b',
		},
		success: {
			main: '#35e398',
			light: '#77ffc9',
			dark: '#00b069',
		},
	},
	typography: {
		button: {
			fontFamily: 'Inter',
			fontWeight: '700',
			textTransform: 'none',
		},

		h1: {
			fontFamily: 'Barlow Condensed',
			fontWeight: '700',
		},
		h2: {
			fontFamily: 'Barlow Condensed',
			fontWeight: '700',
		},
		h3: {
			fontFamily: 'Barlow Condensed',
		},
		h4: {
			fontFamily: 'Barlow Condensed',
		},
		h5: {
			fontFamily: 'Barlow Condensed',
		},
		h6: {
			fontFamily: 'Barlow Condensed',
		},
		subtitle1: {
			fontFamily: 'Roboto',
			fontWeight: '700',
		},
		subtitle2: {
			fontFamily: 'Roboto',
		},
		body1: {
			fontFamily: 'Inter',
		},
		body2: {
			fontFamily: 'Inter',
		},

		//** Custom Variant */
		demoVariant: {
			fontSize: '6rem',
		},
	} as ExtendedTypographyOptions,
} as ThemeOptions)

export const drawerWidth = '20vw'

//** Custom styles constants */

export const customAppStyles = {
	offset: ludusTheme.mixins.toolbar,
	rootBox: { display: 'flex', },
	contentBox: {
		backgroundImage: `url("${backgroundImage}")`,
		backgroundSize: 'cover',
		margin: 'auto',
		display: 'flex',
		minWidth: '100%',
		minHeight: '100vh',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
	},
	drawerBox: { display: { xs: 'none', md: 'block' } },
}

export const customHeaderStyles = {
	offset: ludusTheme.mixins.toolbar,
	menuButton: {
		marginLeft: ludusTheme.spacing(2),
		display: 'block',
	},
	navBarTittle: {
		flexGrow: 1,
		textDecoration: 'none',
		color: 'inherit',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignContent: 'stretch',
	},
}

export default ludusTheme
