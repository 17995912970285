import Container from '@mui/material/Container';
import Ranking from './components/Ranking';
import { customAppStyles } from './ludusTheme.d'
import { Box } from '@mui/material'

function App() {
  return (
    <Box component={'div'} sx={customAppStyles.contentBox}>
      <Container>
        <Ranking />
      </Container>
    </Box>
  );
}

export default App;
